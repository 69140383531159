<style scoped>
.title{
    width: 100vw;
    height: 35vh;
}
.title img{
    width: 100%;
    height: 100%;
}
.content{
    width: 70vw;

    background-color: #CCCCCC;
    margin: 15px auto;
    border-radius: 25px;
    padding: 20px;
}
.product{
    width: 98%;
    background-color: #D8D8D8;
    margin: 0 auto;
    padding: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    line-height: 30px;
    font-weight: 530;
    margin-top: 40px;
}
.state{
    width: 98%;
    background-color: #D8D8D8;
    height: 200px;
    margin: 20px auto;
    padding: 12px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.state div{
    line-height: 40px;
}
.state li{
    line-height: 40px;
}
.time{
    font-size: 12px;
}
</style>
<template>
<div>
    <div class="title"><img src="@/assets/wuliu.png" ></div>
    <div class="content">
        <div>&nbsp;</div>
        <div style="font-size: 30px;font-weight: bold;margin-left:12px;" @click="$router.back()">&lt; 返回</div>
        <div style="display:flex;justify-content: center;align-items: center;margin-top: 25px;">
            <img style="width: 85%;border-radius: 24px;height: 580px;" src="../assets/dt.png">
        </div>
        <div class="product">
            <div><img src="@/assets/qy.png" style="width: 100px;height: 100px;border-radius: 12px;"></div>
            <div>
                <div>土工材料</div>
                <div>×30000</div>
                <div>大连工地</div>
            </div>
            <div>
                <div>
                    电话：18046925763
                </div>
                <div>
                    地址：辽宁省大连市金普新区xx街道xx号
                </div>
                <div>
                    联系人：李先生
                </div>
            </div>
        </div>
        <div class="state">
            <div>
                <li>配送中预计30分钟送达</li>
                <li>发车中</li>
                <li>开始配货</li>
            </div>
            <div class="time">
                <div>2023-12-05 10:12</div>
                <div>2023-12-05 09:40</div>
                <div>2023-12-05 08:10</div>
            </div>
        </div>
    </div>
</div>

</template>
<script>
export default{
    data(){
        return {

        }
    }
}
</script>