<template>
    <div>
        <div class="footer">
            <div>
                <!-- <div class="biaoqian">
                    <a href="https://ec.powerchina.cn/">中国电建|</a>&nbsp;
                    <a href="https://www.crcchc.com/zthcw/">铁建汇采|</a>&nbsp;
                    <a href="https://www.yzw.cn/">云筑网|</a>&nbsp;
                    <a href="http://ec.ccccltd.cn/PMS/">中国供应链管理系统</a>
                </div> -->
                <div>
                    <p>中野战狼（大连）科技有限公司 | 联系电话：0411-66663538 邮箱地址：925896390@qq.com</p>
                    <a href="https://beian.miit.gov.cn/#/Integrated/index" style="color: #313131; text-decoration: none;">
                        <p> 网站备案/许可证号：辽ICP备2022001600号-1</p>
                    </a>
					<a  style="color: #313131; text-decoration: none;">
					    <p> 经营许可证编号：辽B2-20220846</p>
					</a>
                    <p>总访问量：{{ b }} 今日访问量：{{ a }} 昨日访问量：{{ c }}</p>
                    <p> <img style="width: 20px; height: 20px; position:relative; top: 5px; margin-right: 6px;"
                            src="../images/beian.png" alt=""><a style="color:white; text-decoration: none;"
                            href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=21021102001154">辽公网安备21021102001154号</a>
                    </p>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { getVisited } from "../API/axios"
export default {
    props: ['screens'],
    data() {
        return {
            a: 1,
            b: 1,
            c: 1,
        };
    },
    created() {
        getVisited().then((res) => {
            this.a = res.data.data[0].todayCount
            this.b = res.data.data[0].totalCount
            this.c = res.data.data[0].yesterdayCount
        })
    },
}
</script>

<style lang="scss" scoped>
.biaoqian {
    text-align: center;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    margin-top: 1px;
    border-bottom: 1px solid rgb(220, 220, 220);

    a {
        font-size: 2rem;
        color: #ffffff;
        font-weight: 900;
    }

    a:hover {
        color: rgb(255, 154, 65);

    }
}

.footer {
    clear: both;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background: linear-gradient(90deg, rgba(55, 86, 222, 1) 0%, rgba(89, 19, 173, 1) 100%);
    margin-top: 50px;
    padding-top: 30px;
    padding-bottom: 2rem;
}

.footer p {
    text-align: center;
    line-height: 30px;
    color: #ffffff;
}
</style>
