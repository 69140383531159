<template>
    <div class="flexcenter">
        <div class="box">
            <div class="flexcenter">
                <div class="title">{{ wz.title }}</div>
            </div>
            <div class="flexcenter">
                <div class="boxson">
                    <div v-for="item in wz.paragraphs">
                        <div v-for="item in item.contents">
                            <div class="updatesfont"> {{ item.content }}</div>
                        </div>
                        <div class="flexcenter" style="margin-top: 2rem;" v-if="item.image != 1">
                            <div class="boxsonimg" :style="screens > 1030 ? '' : 'height: 5rem;'">
                                <img :src=item.image style="width: 100%;height: 100%;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getAllById } from "../API/axios"
export default {
    props: ['screens'],
    data() {
        return {
            show: !false,
            title: '重庆与央企携手合作落实成渝地区双城经济圈建设国家战略 共建现代化新重庆座谈会在京举行',
            arr: [],
            id: this.$route.query.id,
            wz: [],
        }
    },
    methods: {
        infoWindowClose() {
            this.show = false
        },
        infoWindowOpen() {
            this.show = true
        },
        mySplit(str, leng) {
            let index = 0;
            while (index < str.length) {
                let strs = str.slice(index, index += leng)
                this.arr.push(strs);
            }
        }
    },
    mounted() {
        if (this.title.length > 26) {
            this.mySplit(this.title, 26);
        }
    },
    created() {
        getAllById(this.id).then((res) => {
            this.wz = res.data.data[0]
            console.log(this.wz)
        })
    },
}
</script>

<style scoped>
.box {
    background: rgba(255, 255, 255, 1);
    width: 80%;
    margin-top: 1rem;
    padding: 3rem;
}

.title {
    color: rgba(0, 0, 0, 1);
    font-size: 2rem;
    line-height: 35px;
    font-weight: 500;
    font-family: 'SourceHanSansCN-Medium';
    margin-top: 0.5rem;
}

.boxson {
    width: 90%;
    background: rgba(204, 204, 204, 1);
    padding: 2rem;
    margin-top: 2rem;
}

.boxsonimg {
    width: 50%;
    height: 20rem;
}

.updatesfont {
    color: rgba(0, 0, 0, 1);
    font-size: 24px;
    line-height: 55px;
    font-weight: 500;
    font-family: 'SourceHanSansCN-Medium';
    text-indent: 2em;
}
</style>