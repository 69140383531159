<template>
    <div class="introduction">
        <div>
            <img src="../assets/cy.png" :style="screens > 1030 ? '' : 'height: 15rem;'" style="width: 100%;height: 30rem;">
        </div>

        <div class="flexcenter">
            <div class="bottomBox">
                <div class="flexcenter">
                    <el-row :gutter="40" style="width: 100%;">
                        <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" v-for="item in arr">
                            <div @click="clickBox" class="bottomBoxs"
                                :style="screens >= 1030 ? 'height: 305px;' : 'height: 205px;'">
                                <img :src=item.image style="width: 100%;height: 100%;">
                                <div class="bottomBoxsfont">{{ item.imageTitle }}</div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { getList } from "../API/axios"
export default {
    props: ['screens'],
    data() {
        return {
            name: {},
            id: this.$route.query.id,
            arr: [],
        }
    },
    created() {
        getList().then((res) => {
            console.log(res.data.data)
            this.arr = res.data.data
        })
    },
    methods: {
        clickBox() {
            this.$router.push({ name: 'industryDetail' })
        },
    }
}
</script>

<style lang="less" scoped>
@import '../style/basic.less';

.introduction {
    width: 100%;
    overflow-x: hidden;

    .top {
        background: url(../images/lj1.png) no-repeat;
        height: 50rem;
        width: 100%;
        background-size: cover;
        padding: 2rem 1rem 0 1rem;
    }

    .box {
        border-radius: 3rem;
        background: @backgroundwhite;
        border: 0.2rem solid rgba(71, 71, 71, 1);
    }

    .font {
        display: flex;
        flex-direction: column;
        justify-content: start;
        height: 44px;
        rotate: 90.08075349608389deg;
        color: @backgroundwhite;
        font-size: 30px;
        font-weight: @fontW;
        font-family: @fontB;
        white-space: nowrap;
        width: 2rem;
        margin-right: 1rem;
    }

    .rightFont {
        list-style: none;
        color: @backgroundwhite;
        font-size: 24px;
        font-weight: @fontW;
        font-family: @fontB;
        margin-bottom: 2rem;
        font-size: 2rem;
    }

    .bottomBox {
        width: 80%;
        background: @backgroundg;
        padding: 3rem;
    }

    .bottomBoxs {
        border-radius: 10px;
        background: @backgroundg;
        margin-bottom: 5rem;
    }

    .bottomBoxsfont {
        font-size: 1.5rem;
        font-weight: 500;
        color: rgba(0, 0, 0, 1);
        text-align: center;
    }
}
</style>