<template>
  <div>
    <el-container>
      <el-header style="height: 9rem;">
        <div style="height: 9rem;z-index: 99;width: 100%;background: rgb(240, 240, 240);position: fixed;">
          <div v-if="this.screenWidth < 1030">
            <i @click="drawer = true" class="el-icon-s-operation"
              style="line-height: 3rem; font-size: 2rem;color:#909399;"></i>
          </div>
          <Header :screen="this.screenWidth"></Header>
        </div>
      </el-header>
      <el-main>
        <Body :screen="this.screenWidth"></Body>
      </el-main>
      <el-footer>
        <Footer></Footer>
      </el-footer>
    </el-container>
    <!-- <div>
      <el-drawer title="我是标题" :visible.sync="drawer" :direction="direction" size="50%" :with-header="false">
        <div style=" background: #606266; width: 100%;height: 100%;">
          <el-menu default-active="1" class="el-menu-vertical-demo" background-color="#545c64" text-color="#fff"
            active-text-color="#ffd04b">
            <router-link to="/index" style="color: white;">
              <el-menu-item index="1">
                <i class="el-icon-s-home"></i>
                <span slot="title">首页</span>
              </el-menu-item></router-link>
            <router-link to="/jiandang" style="color: white;">
              <el-menu-item index="6">
                <i class="el-icon-s-promotion"></i>
                <span slot="title">党建</span>
              </el-menu-item></router-link>
            <router-link to="/announcement" style="color: white;">
              <el-menu-item index="7">
                <i class="el-icon-s-promotion"></i>
                <span slot="title">中标公告</span>
              </el-menu-item></router-link>
            <router-link to="/dynamic" style="color: white;">
              <el-menu-item index="3">
                <i class="el-icon-user-solid"></i>
                <span slot="title">企业动态</span>
              </el-menu-item></router-link>
            <router-link to="/industry" style="color: white;">
              <el-menu-item index="4">
                <i class="el-icon-s-order"></i>
                <span slot="title">公司产品</span>
              </el-menu-item></router-link>
            <router-link to="/culture" style="color: white;">
              <el-menu-item index="5">
                <i class="el-icon-location"></i>
                <span slot="title">企业文化</span>
              </el-menu-item></router-link>
            <router-link to="/guanyuwomen" style="color: white;">
              <el-menu-item index="2">
                <i class="el-icon-star-on"></i>
                <span slot="title">关于我们</span>
              </el-menu-item></router-link>
          </el-menu>
        </div>
      </el-drawer>
    </div> -->
  </div>
</template>

<script>
import Header from './header.vue';
import Footer from './footer.vue';
import Body from './Body.vue'
export default {
  data() {
    return {
      drawer: false,
      direction: 'ltr',
      screenWidth: document.documentElement.clientWidth,
    };
  },
  components: {
    Header,
    Footer,
    Body
  },
  created() {
    this.screenWidth = document.documentElement.clientWidth
  },
  mounted() {
    const that = this;
    window.onresize = function () {
      that.screenWidth = document.documentElement.clientWidth
    };
  },
  updated() {
    const that = this;
    window.onresize = function () {
      that.screenWidth = document.documentElement.clientWidth
    };
  }
}
</script>

<style scoped>
.el-header {
  height: 9rem !important;
  padding: 0 !important;
}

.el-main {
  overflow: hidden !important;
  padding: 0 !important;
  margin: 0 !important;
}

.el-footer {
  padding: 0 !important;
  margin: 0 !important;
}
</style>