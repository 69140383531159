<template>
    <div style="height: 100%;">
        <div>
            <img src="../assets/gywm.png" :style="screens > 1030 ? '' : 'height: 15rem;'"
                style="width: 100%;height: 30rem;">
        </div>
        <div class="flexcenter" style="margin-top: 2rem;">
            <div style="width: 70%;">
                <div style="display: flex;justify-content: space-between;">
                    <div class="left">
                        <div class="gywm">关于我们</div>
                        <div v-for="item in gywm" style="margin-top: 5%;">
                            <div class="gywmfont" @click="change = item.font">{{ item.font }}</div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="rightfont">
                            <div v-if="change == '公司历史'">
                                <div>企业供应链管理创新：</div>
                                <div>
                                    加大供应商管理力度满足客户需求，增强供应链管理系统信息交流的准确性与频繁性，进而提高客户关系管理的效率，提高对客户需求以及消费行为分析的准确性，并结合分析结果对市场战略进行合理化调整。
                                </div>
                                <div>
                                    提升企业数字化水平：建立数字化系统和数据库等，使得数据资产价值最大化，破除信息和业务孤岛，提升数据信息传递速度，实现数据共享，更好地服务于管理决策。例如速达软件旗下的企业管理软件就是不错的选择。
                                </div>
                                <div>加强企业内外部供应链管理：准确完成需求计划预测，科学地管理库存，加大内部供应链的执行力度，与供应链上下游企业建立长期的战略合作关系，整合优质资源，实现技术资源共享.
                                </div>
                                <div style="margin-top: 2rem;">提升物流服务能力：</div>
                                <div>建立和完善综合物流体系。在综合物流体系建设中，要把物流信息平台建设放在首位。整合物流资源，实现物流规模经营。</div>
                                <div>城市间的快速货运和专线运输；为厂家、商家提供快捷和便宜的货运仓储服务；提供特色运输和专项运输，企业配送和城市配送</div>
                                <div>造就一批高素质的物流经营管理人才。</div>
                                <div>大力加强物流信息化建设。现代物流的合理运营以信息流的科学管理为前提。</div>
                            </div>
                            <div v-if="change == '领导团队'">
                                <div v-for="item in arr">
                                    <el-row :gutter="20"
                                        style="width: 100%; display: flex;justify-content: space-between;flex-direction: row; flex-wrap: wrap;flex-shrink:1;">
                                        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" v-for="it in item">
                                            <div class="people">
                                                <img :src=it.image style="width: 100%;height: 100%;">
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                            <div v-if="change == '企业资质'">
                                <el-row :gutter="20" style="width: 100%;">
                                    <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                                        <div class="qiyebox">
                                            <img src="../assets/1.png" style="width: 100%;height: 100%;">
                                        </div>
                                    </el-col>
                                    <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                                        <div class="qiyebox">
                                            <img src="../assets/2.png" style="width: 100%;height: 100%;">

                                        </div>
                                    </el-col>
                                    <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                                        <div class="qiyebox">
                                            <img src="../assets/3.png" style="width: 100%;height: 100%;">
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="20" style="width: 100%;">
                                    <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                                        <div class="qiyebox">
                                            <img src="../assets/4.png" style="width: 100%;height: 100%;">
                                        </div>
                                    </el-col>
                                    <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                                        <div class="qiyebox">
                                            <img src="../assets/5.png" style="width: 100%;height: 100%;">
                                        </div>
                                    </el-col>
                                    <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                                        <div class="qiyebox">
                                            <img src="../assets/6.png" style="width: 100%;height: 100%;">
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                            <div v-if="change == '企业文化'">
                                <div>公司起源：中野战狼（大连）科技有限公司于2014年7月21日注册成立，注册资金2800万。</div>
                                <div>公司地点：位于美丽的海滨城市大连。</div>
                                <div>主要经营产品：五金机电劳保护具、金属结构制造、新材料研发，等建筑材料。</div>
                                <div>主要服务客户：中铁、中建、中电、中交等大型国有建筑企业。</div>
                                <div>
                                    经营范围包括许可项目：第二类增值电信业务（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以审批结果为准）一般项目：金属结构制造，金属制品销售，金属制品研发，新材料技术研发，五金产品批发，建筑材料销售，劳动保护用品销售，产业用纺织制成品销售，化工产品销售（不含许可类化工产品），技术进出口，货物进出口，建筑防水卷材产品销售（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getTeamList } from "../API/axios"
export default {
    props: ['screens'],
    data() {
        return {
            show: !false,
            gywm: [
                { font: '公司历史' },
                { font: '领导团队' },
                { font: '企业资质' },
                { font: '企业文化' },
            ],
            change: this.$route.query.name,
            arr: [],
        }
    },
    methods: {
        infoWindowClose() {
            this.show = false
        },
        infoWindowOpen() {
            this.show = true
        },
        xiangqing() {
            this.$router.push('/xiangqing')
        },
        group(array = [], subGroupLength = 0) {
            let index = 0;
            const newArray = [];
            while (index < array.length) {
                newArray.push(array.slice(index, index += subGroupLength));
            }
            return newArray;
        }
    },
    watch: {
        $route(to, from) {
            this.change = to.query.name
        }
    },
    created() {
        getTeamList().then((res) => {
            this.arr = this.group(res.data.data, 5)
        })
    },
}
</script>
<style scoped>
.left {
    border-radius: 1rem;
    background: rgba(224, 224, 224, 1);
    width: 20%;
    height: 50%;
}

.right {
    border-radius: 1rem;
    background: rgba(204, 204, 204, 1);
    width: 78%;
    padding: 1rem;
}

.gywm {
    mix-blend-mode: multiply;
    border-radius: 1rem;
    background: rgba(71, 127, 191, 1);
    font-size: 2rem;
    font-weight: 500;
    line-height: 3rem;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    padding: 1rem;
    transform: translate(-4rem, 0)
}

.gywmfont {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2.5rem;
    color: rgba(0, 0, 0, 1);
    text-align: center;
}

.rightfont {
    font-size: 1rem;
    font-weight: 400;
    line-height: 3rem;
    color: rgba(0, 0, 0, 1);
}

.people {
    border-radius: 18px;
    background: rgba(163, 163, 163, 1);
    height: 15rem;
    margin-top: 1rem;
}
</style>