<template>
    <div>

        <Head></Head>
        <Navigation></Navigation>
        <div class="flexcenter">
            <div class="customer">
                <div class="customerhead">
                    <i class="el-icon-arrow-left" @click="go" style="float: left;line-height: 7rem;font-size: 2rem;"></i>
                    <div class="customerheadfont">在线留言</div>
                </div>
                <div class="customermiddle" ref="scrollBox" id="box">
                    <div v-for="item in userListData">
                        <div style="display: flex; margin-top: 1rem;"
                            :style="item.position == 'left' ? '' : 'flex-direction: row-reverse;'">
                            <div class="demo-basic--circle">
                                <div class="block"><el-avatar shape="square" :size="50" :src=item.url></el-avatar></div>
                            </div>
                            <img :src=item.img style="width: 10rem;height: 6rem;" v-if="item.img != ''"
                                :style="item.position == 'left' ? 'margin-left: 1rem;' : 'margin-right: 1rem;'">
                            <div v-if="item.info != ''" class="customermiddleleft"
                                :style="item.position == 'left' ? 'margin-left: 1rem;' : 'background: rgba(148, 196, 43, 1);margin-right: 1rem;'">
                                {{ item.info }}</div>
                        </div>
                    </div>
                </div>
                <div class="customerfoot">
                    <div style="display: flex;line-height: 5rem;">
                        <el-input v-model="value" style="flex: 1;"></el-input>
                        <el-upload accept="image/*" :show-file-list="false" :on-change="fileimg" :auto-upload="false"
                            action="#">
                            <i class="el-icon-picture-outline-round"
                                style="line-height: 5rem;margin-left: 1rem;font-size: 2rem;"></i>
                        </el-upload>
                        <el-button
                            style="margin-left: 1rem;border-radius: 1rem;background: rgba(174, 219, 77, 1);height: 3rem;margin-top: 1rem;"
                            @click="setUp">发送</el-button>
                    </div>
                </div>
            </div>
        </div>
        <Foot></Foot>
    </div>
</template>

<script>
import { setFromDataGongYingShang } from "../API/axios"
export default {
    mounted() {
        // this.initWebpack();
        // console.log(JSON.parse(sessionStorage.getItem('userListData')))
        if (JSON.parse(sessionStorage.getItem('userListData'))) {
            this.userListData = JSON.parse(sessionStorage.getItem('userListData'))
        }
        // 页面滚动到底部
        this.$nextTick(() => { // 一定要用nextTick
            this.setPageScrollTo();
            //页面滚动条距离顶部高度等于这个盒子的高度
            this.$refs.scrollBox.scrollTop = this.$refs.scrollBox.scrollHeight;
        })
    },
    // beforeDestroy() {
    //     // 离开页面后关闭连接
    //     this.ws.close();
    //     // 清除时间
    //     clearTimeout(this.timeoutObj);
    //     clearTimeout(this.serverTimeoutObj);
    // },
    data() {
        return {
            //websocket部分
            path: "ws://localhost:8888", //后台的websocket地址，找后端要
            ws: null, //建立的连接
            lockReconnect: false, //是否真正建立连接
            timeout: 10 * 1000, //30秒一次心跳
            timeoutObj: null, //心跳心跳倒计时
            serverTimeoutObj: null, //心跳倒计时
            timeoutnum: null, //断开 重连倒计时
            value: '',
            userListData: [
                {
                    url: "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
                    username: "用户",
                    info: "在吗",
                    img: "",
                    timer: "2022/8/9",
                    position: "left",
                },
                {
                    url: "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
                    username: "客服",
                    info: "在",
                    img: "",
                    timer: "2022/8/9",
                    position: "right",
                },
                {
                    url: "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
                    username: "用户",
                    info: "您好，我已支付可以帮忙提交申请吗？",
                    img: "",
                    timer: "2022/8/9",
                    position: "left",
                },
                {
                    url: "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
                    username: "客服",
                    info: "好的没问题，麻烦您这边提供一下所需的图片和信息。",
                    img: "",
                    timer: "2022/8/9",
                    position: "right",
                },
                {
                    url: "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
                    username: "用户",
                    info: "谢谢",
                    img: "",
                    timer: "2022/8/9",
                    position: "left",
                },
            ],
            //滚动条距离顶部距离
            scrollTop: 0,
            fileList: [],
            img: "",
        };
    },
    watch: {
        "ruleForm.gongyingsahngleibie"(v) {

        }
    },
    methods: {
        async fileimg(file) {
            const reader = new FileReader();
            reader.readAsDataURL(file.raw)
            reader.onload = e => {
                this.img = e.target.result;
                this.userListData.push({
                    url: "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
                    username: "客服",
                    info: "",
                    img: this.img,
                    timer: "2022/8/1",
                    position: "right",
                });
                sessionStorage.setItem('userListData', JSON.stringify(this.userListData));
                // console.log(JSON.parse(sessionStorage.getItem('userListData')))
                // 页面滚动到底部 
                this.$nextTick(() => { // 一定要用nextTick
                    this.setPageScrollTo();
                    //页面滚动条距离顶部高度等于这个盒子的高度
                    this.$refs.scrollBox.scrollTop = this.$refs.scrollBox.scrollHeight;
                })
            }
            this.img = "";
        },
        go() {
            this.$router.go(-1)
        },
        //发送
        setUp() {
            if (this.value) {
                this.userListData.push({
                    url: "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
                    username: "客服",
                    info: this.value,
                    img: "",
                    timer: "2022/8/1",
                    position: "right",
                });
                // this.userListData.push({
                //     url: "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
                //     username: "用户",
                //     info: this.value,
                // img:"",
                //     timer: "2022/8/1",
                //     position: "left",
                // });
                sessionStorage.setItem('userListData', JSON.stringify(this.userListData));
                // console.log(JSON.parse(sessionStorage.getItem('userListData')))
                this.value = "";
                // 页面滚动到底部
                this.$nextTick(() => { // 一定要用nextTick
                    this.setPageScrollTo();
                    //页面滚动条距离顶部高度等于这个盒子的高度
                    this.$refs.scrollBox.scrollTop = this.$refs.scrollBox.scrollHeight;
                })
            } else {
                alert("请输入内容！");
            }
        },
        setPageScrollTo(s, c) {
            //获取中间内容盒子的可见区域高度
            this.scrollTop = document.querySelector("#box").offsetHeight;
            setTimeout((res) => {
                //加个定时器，防止上面高度没获取到，再获取一遍。
                if (this.scrollTop != this.$refs.scrollBox.offsetHeight) {
                    this.scrollTop = document.querySelector("#box").offsetHeight;
                }
            }, 100);
            //scrollTop：滚动条距离顶部的距离。
            //把上面获取到的高度座位距离，把滚动条顶到最底部
            this.$refs.scrollBox.scrollTop = this.scrollTop;
            //判断是否有滚动条,有滚动条就创建一个监听滚动事件，滚动到顶部触发srTop方法
            if (this.$refs.scrollBox.scrollTop > 0) {
                this.$refs.scrollBox.addEventListener("scroll", this.srTop);
            }
        },
        //-----------------------以下是websocket部分方法

        // 初始化websocket链接
        initWebpack() {
            if (typeof WebSocket === "undefined") {
                alert("您的浏览器不支持socket");
            } else {
                this.ws = new WebSocket(this.path); //实例
                this.ws.onopen = this.onopen; //监听链接成功
                this.ws.onmessage = this.onmessage; //监听后台返回消息
                this.ws.onclose = this.onclose; //监听链接关闭
                this.ws.onerror = this.onerror; //监听链接异常
            }
        },
        //重新连接
        reconnect() {
            var that = this;
            if (that.lockReconnect) {
                return;
            }
            that.lockReconnect = true;
            //没连接上会一直重连，设置延迟避免请求过多
            that.timeoutnum && clearTimeout(that.timeoutnum);
            that.timeoutnum = setTimeout(function () {
                that.initWebpack(); //新连接
                that.lockReconnect = false;
            }, 5000);
        },
        //重置心跳
        reset() {
            var that = this;
            clearTimeout(that.timeoutObj); //清除心跳倒计时
            clearTimeout(that.serverTimeoutObj); //清除超时关闭倒计时
            that.start(); //重启心跳
        },
        //开启心跳
        start() {
            var self = this;
            self.timeoutObj && clearTimeout(self.timeoutObj); //心跳倒计时如果有值就清除掉，防止重复
            self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj); //超时关闭倒计时如果有值就清除掉，防止重复
            //然后从新开一个定时器
            self.timeoutObj = setTimeout(function () {
                //这里通过readyState判断链接状态，有四个值，0：正在连接，1：已连接，2：正在断开，3：已经断开或者链接不成功
                if (self.ws.readyState == 1) {
                    //如果连接正常，给后天发送一个值，可以自定义，然后后台返回我们一个信息，我们接收到后会触发onmessage方法回调
                    self.ws.send("ping");
                } else {
                    //如果检测readyState不等于1那也就代表不处在链接状态，那就是不正常的，那就调用重连方法
                    self.reconnect();
                }
                //从新赋值一个超时计时器，这个定时器的作用：当你触发心跳的时候可能会出现一个情况，后台崩了，前台发了个心跳，没有回应，就不会触发onmessage方法
                //所以我们需要在这个心跳发送出去了后，再开一个定时器，用于监控心跳返回的时间，比如10秒，那么10秒内如果后台回我了，触发onmessage方法，自然就会把心跳时间和超时倒计时一起清空掉
                //也就不会触发这个关闭连接，但是如果10秒后还是没有收到回应，那么就会触发关闭连接，而关闭连接方法内又会触发重连方法，循环就走起来了。
                self.serverTimeoutObj = setTimeout(function () {
                    //如果超时了就关闭连接
                    self.ws.close();
                }, self.timeout);
            }, self.timeout);
        },
        //连接成功
        onopen() {
            console.log("连接成功");
            if (this.ws.readyState == 1) {
                //如果连接正常，给后天发送一个值，可以自定义，然后后台返回我们一个信息，我们接收到后会触发onmessage方法回调
                this.ws.send(
                    "链接上啦！！！"
                );
            }
            this.start(); //链接成功后开启心跳
        },
        //接受后台信息回调
        onmessage(e) {
            /**这里写自己的业务逻辑代码**/
            console.log("收到后台信息:", e.data);
            this.reset(); //收到服务器信息，心跳重置
        },
        //关闭连接回调
        onclose(e) {
            console.log("连接关闭");
            this.reconnect(); //重连
        },
        //连接异常回调
        onerror(e) {
            console.log("出现错误");
            this.reconnect(); //重连
        },
    },
}
</script>

<style lang="scss">
.customer {
    width: 70%;
    background: rgba(204, 204, 204, 1);

    .customerhead {
        background: rgba(255, 255, 255, 1);
        height: 7rem;
        width: 100%;
        padding: 0 1rem;
    }

    .customermiddle {
        width: 100%;
        background: rgba(204, 204, 204, 1);
        padding: 0 1rem 1rem;
        height: 35rem;
        overflow: auto;
    }

    .customerfoot {
        background: rgba(255, 255, 255, 1);
        height: 5rem;
        width: 100%;
        padding: 0 1rem;
    }

    .customerheadfont {
        font-size: 2rem;
        font-weight: 500;
        line-height: 7rem;
        text-align: center;
    }

    .customermiddleleft {
        border-radius: 1rem;
        background: rgba(149, 161, 124, 1);
        padding: 1rem;
        font-size: 1rem;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
    }

    .customermiddle::-webkit-scrollbar {
        display: none;
    }
}
</style>