<template>
    <div style="height: 100%;">
        <div>
            <img src="../assets/zbgg.png" :style="screens > 1030 ? '' : 'height: 15rem;'"
                style="width: 100%;height: 30rem;">
        </div>
        <div class="flexcenter" style="margin-top: 2rem;">
            <div style="width: 70%;">
                <div style="display: flex;justify-content: space-between;">
                    <div class="gywm">联系方式</div>
                    <div class="right">
                        <div class="right_map">
                            <img src="../assets/dt.png" style="width: 100%;height: 100%;">
                        </div>
                        <div class="right_box">
                            <div class="right_font">公司地址：辽宁省大连市甘井子区金龙路15号</div>
                        </div>
                        <div class="right_box">
                            <div class="right_font">电话：15942408590</div>
                        </div>
                        <div class="right_box">
                            <div class="right_font">邮箱：925896390@qq.com</div>
                        </div>
                    </div>
                    <div style=" display: flex;align-items: flex-end;">
                        <div @click="contactPage" class="gywm_bottom">在线留言</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getshouyeimg, shouyelist, getEssayById } from "../API/axios"
export default {
    props: ['screens'],
    data() {
        return {
            show: !false,
            arrdata: [],
        }
    },
    methods: {
        infoWindowClose() {
            this.show = false
        },
        infoWindowOpen() {
            this.show = true
        },
        xiangqing(id) {
            this.$router.push({ name: 'adetail', query: { id: id } })
        },
        contactPage() {
            this.$router.push({ name: 'customer' })
        }
    },
    created() {
        getEssayById("中标公告").then((res) => {
            this.arrdata = res.data.data
            console.log(this.arrdata)
        })
    },
}
</script>

<style scoped>
.right {
    border-radius: 1rem;
    background: rgba(204, 204, 204, 1);
    width: 68%;
    padding: 1rem;
}

.right_map {
    border-radius: 16px;
    background: rgba(204, 204, 204, 1);
}

.right_box {
    border-radius: 9px;
    background: rgba(224, 224, 224, 1);
    padding: 1rem;
    margin-top: 1rem;
}

.right_font {
    font-size: 30px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    vertical-align: top;
}

.gywm {
    mix-blend-mode: multiply;
    border-radius: 1rem;
    background: rgba(6, 28, 150, 1);
    font-size: 2rem;
    font-weight: 500;
    line-height: 3rem;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    padding: 1rem;
    height: 10%;
    width: 20%;
}

.gywm_bottom {
    border-radius: 1rem;
    background: rgba(204, 204, 204, 1);
    font-size: 2rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    text-align: center;
    padding: 1rem;
    width: 100%;
}

.rightlist {
    border-radius: 0.8rem;
    background: rgba(224, 224, 224, 1);
    width: 100%;
    margin-bottom: 1rem;
    padding: 1rem;
}

.rightlisttitle {
    font-size: 2rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
}

.rightlistdata {
    font-size: 1rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.63);
    text-align: right;
    margin-top: 1rem;
}
</style>