<template>
    <div style="width: 100%;height: 100%;">
        <el-row :style="screen >= 1030 ? '' : 'margin-top: -1rem;'">
          <div class="logo-box">
                <div class="logo">
                    <img src="../images/logotouming.png">
                    <div class="logo-text">
                        <span class="logo-title"
                            :style="screen >= 1030 ? 'font-size:2rem;' : 'font-size:1rem;'">中野战狼（大连）科技有限公司</span><br>
                        <span class="logo-titles" :style="screen >= 1030 ? 'font-size:1.5rem;' : 'font-size:0.2rem;'">
                            Zhongye warwolf (Dalian) Instrument Co., Ltd</span>
                    </div>
                </div>
                <div class="header_font">
                    采购热线：15942408590
                </div>
          </div>
        </el-row>
    </div>
</template>
<script>
export default {
    props: ['screen'],
}
</script>
<style scoped>
.logo {
    display: flex;
    margin-top: 2rem;
}

.el-form {
    margin-top: 90px;
}

.logo img {
    width: 8rem;
    height: 6rem;
}

.logo-title {
    font-weight: bold;
    font-size: 1rem;
}

.logo-titles {
    font-size: 0.2rem;
}

.logo-text {
    padding-top: 1rem;
    color: rgb(13, 58, 149);
    line-height: 2rem;
}

.header_font {
    font-size: 24px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    vertical-align: top;
    margin-top: 3rem;
}
.logo-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 60px;
}
</style>
