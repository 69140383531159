<template>
    <div class="flexcenter">
        <div style="width: 60%;">
            <div class="head">
                <i class="el-icon-arrow-left" style="float: left;font-size: 2rem;line-height: 5rem;" @click="fh"></i>
                <div class="headtitle">{{ wz.title }}</div>
                <div class="headdata">{{ wz.createTime }}</div>
            </div>
            <div class="bottom">
                <div v-for="item in wz.paragraphs">
                    <div v-for="item in item.contents">
                        <div class="bottomfont">{{ item.content }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getshouyeimg, shouyelist, getAllById } from "../API/axios"
export default {
    props: ['screens'],
    data() {
        return {
            show: !false,
            wz: [],
            id: this.$route.query.id,
        }
    },
    methods: {
        infoWindowClose() {
            this.show = false
        },
        infoWindowOpen() {
            this.show = true
        },
        fh() {
            this.$router.go(-1)
        }
    },
    created() {
        getAllById(this.id).then((res) => {
            this.wz = res.data.data[0]
            console.log(this.wz)
        })
    },
}
</script>

<style scoped>
.head {
    border-radius: 0.7rem;
    background: rgba(224, 224, 224, 1);
    padding: 1rem;
}

.headtitle {
    font-size: 2rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    text-align: center;
}

.headdata {
    font-size: 1rem;
    font-weight: 400;
    line-height: 3rem;
    color: rgba(0, 0, 0, 0.63);
    text-align: center;
}

.bottom {
    background: rgba(204, 204, 204, 1);
    padding: 1rem;
}

.bottomfont {
    font-size: 1rem;
    font-weight: 400;
    line-height: 2rem;
    color: rgba(0, 0, 0, 1);
}
</style>