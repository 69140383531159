<template>
    <div style="height: 100%;">
        <div>
            <img src="../assets/qywh.png" :style="screens > 1030 ? '' : 'height: 15rem;'" style="width: 100%;height: 30rem;">
        </div>
        <div class="flexcenter">
            <div class="bottom">
                <div class="bottomtitle" :style="screens > 1030 ? '' : 'font-size: 2rem;'">— —企业精神— —</div>
                <div class="flexcenter">
                    <div class="box1">以质量求生存，以信誉促发展。坚持团结稳定，齐心协力干事业。</div>
                </div>
                <div class="bottomtitle" :style="screens > 1030 ? '' : 'font-size: 1.8rem;'">— —核心价值观— —</div>
                <div class="flexcenter">
                    <div class="box1">态度决定一切，细节决定成败，目标决定方向，行动决定未来。</div>
                </div>
                <div class="flexcenter" style="margin-top: 2rem;">
                    <div style="width: 100%;">
                        <div
                            style="background:url(https://zl-925896390-1317109654.cos.ap-nanjing.myqcloud.com/2023/08/14/a079add740e44b9eb83c2c0e9b902574.jpg) no-repeat;border-radius: 34px;padding:1rem 1rem 2rem 1rem;background-size: 100%;">
                            <div class="bottomtitle" style="color: white;" :style="screens > 1030 ? '' : 'font-size: 1.8rem;'">—
                                —工作理念— —</div>
                            <div class="flexcenter">
                                <el-row
                                    style="width: 70%; margin-top: 2rem; display: flex;justify-content: space-between;flex-direction: row; flex-wrap: wrap;flex-shrink:1;"
                                    :gutter="40">
                                    <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                                        <div class="bottomboxs">
                                            <div class="bottomboxsfont1">经营理念</div>
                                            <div class="bottomboxsfont2">以诚为本，合作共赢</div>
                                            <div class="bottomboxsfont3"> &nbsp &nbsp &nbsp &nbsp
                                                以诚信为基本要素，在遵守法律道德的基准上与合作方携手共进，达到双赢的局面。</div>
                                        </div>
                                    </el-col>
                                    <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                                        <div class="bottomboxs">
                                            <div class="bottomboxsfont1">管理理念</div>
                                            <div class="bottomboxsfont2">合作协同，效率为先</div>
                                            <div class="bottomboxsfont3"> &nbsp &nbsp &nbsp &nbsp
                                                员工之间携手共进，不同方向的员工相互协作，以最高最快的效率完成当下的工作安排。</div>
                                        </div>
                                    </el-col>
                                    <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                                        <div class="bottomboxs">
                                            <div class="bottomboxsfont1">质量理念</div>
                                            <div class="bottomboxsfont2">匠心制造，质量为先</div>
                                            <div class="bottomboxsfont3"> &nbsp &nbsp &nbsp &nbsp
                                                以百分之百的认真进行产品的制造，万事以产品质量为先，让合作方放心使用。</div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="flexcenter">
                    <div style="width: 80%;padding: 1rem 1rem 0 1rem;margin-bottom: 1rem;">
                        <div class="bottomtitle" :style="screens > 1030 ? '' : 'font-size: 1.4rem;'">— —企业愿景— —</div>
                        <div class="flexcenter">
                            <div class="bottomboxss">
                                <div class="bottomboxssfont">
                                    &nbsp &nbsp &nbsp &nbsp 公司致力于建筑材料制造研发运输领域，以让大企国企放心为己任，以工程制造为基础，运用科学技术为客户提供第一时间的支持与帮助。
                                </div>
                                <div class="bottomboxssfont">
                                    &nbsp &nbsp &nbsp &nbsp
                                    公司秉承“以质量求生存，以信誉促发展。坚持团结稳定，齐心协力干事业”的企业精神。用自身实力和科技创新发展，让员工进步令公司发展，使中国社会更加繁荣昌盛。
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['screens'],
    data() {
        return {
            show: !false,
        }
    },
    methods: {
        infoWindowClose() {
            this.show = false
        },
        infoWindowOpen() {
            this.show = true
        },
        tz() {
            this.$router.push('/interior')
        },
    },
}
</script>

<style scoped>
.box1 {
    border-radius: 33px;
    background: rgba(204, 204, 204, 1);
    border: 6px solid rgba(6, 69, 150, 1);
    width: 80%;
    margin-top: 1rem;
    font-size: 2rem;
    font-weight: 400;
    line-height: 3rem;
    color: rgba(0, 0, 0, 1);
    text-align: center;
    padding: 3rem;
}

.bottom {
    width: 80%;
}

.bottomtitle {
    font-size: 2.5rem;
    font-weight: 700;
    color: rgba(0, 0, 0, 1);
    text-align: center;
    margin-top: 2rem;
}

.bottomboxs {
    border-radius: 1rem;
    background: rgba(255, 255, 255, 1);
    box-shadow: 7px 9px 4px 6px rgba(0, 0, 0, 0.25);
    padding: 2rem;
    margin-top: 1rem;
}

.bottomboxss {
    border-radius: 2rem;
    background: rgba(204, 204, 204, 1);

    border: 6px solid rgba(6, 69, 150, 1);
    margin-top: 1rem;
    padding: 3rem;
}

.bottomboxsfont1 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
    color: rgba(0, 0, 0, 1);
    text-align: center;
}

.bottomboxsfont2 {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 3rem;
    color: rgba(0, 0, 0, 1);
    text-align: center;
}

.bottomboxsfont3 {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 2rem;
    color: rgba(0, 0, 0, 1);
}

.bottomboxssfont {
    font-size: 1rem;
    font-weight: 400;
    line-height: 2rem;
    color: rgba(0, 0, 0, 1);
}</style>