<template>
    <div style="height: 100%;">
        <div>
            <img src="../assets/qydt.png" :style="screens > 1030 ? '' : 'height: 15rem;'"
                style="width: 100%;height: 30rem;">
        </div>
        <div class="flexcenter">
            <div class="bottom">
                <div class="bottomtitle" :style="screens > 1030 ? '' : 'font-size: 2rem;'">— —新闻动态— —</div>
                <div class="flexcenter">
                    <div style="width: 80%; padding: 1rem;border-radius: 2rem;background: rgba(204, 204, 204, 1);margin-top: 2rem;"
                        :style="screens > 1030 ? '' : 'width: 100%;'">
                        <div class="flexcenter">
                            <el-row
                                style="width: 100%; display: flex;justify-content: space-between;flex-direction: row; flex-wrap: wrap;flex-shrink:1;"
                                :gutter="40">
                                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                                    <img src="../assets/qy.png" style="width: 100%;height: 20rem;">
                                </el-col>
                                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="newsboxs">
                                    <div v-for="item in arrdata1">
                                        <div class="qydtfont" @click="tz(item.id)">
                                            <div class="fontss">{{ item.title }}</div>
                                            <div style="width:99px;text-align: right;">{{ item.createTime }}</div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </div>
                <div class="flexcenter" style="margin-top: 2rem;">
                    <div style="width: 100%;">
                        <div
                            style="background: url(https://s11.ax1x.com/2024/01/13/pFPGXG9.png) no-repeat;border-radius: 34px;padding:1rem 1rem 2rem 1rem;background-size: 100%;">
                            <div class="bottomtitle" style="color: white;"
                                :style="screens > 1030 ? '' : 'font-size: 2rem;'">—
                                —行业资讯— —</div>
                            <el-row class="bottomrow"
                                style=" margin-top: 2rem; display: flex;justify-content: space-between;flex-direction: row; flex-wrap: wrap;flex-shrink:1;">
                                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                                    <div v-for="item in arr2[0]" style="border-right: 1px solid white;padding:0 2rem;"
                                        :style="screens > 1030 ? '' : 'border:none;padding:0;'">
                                        <div class="qydtfont" :style="screens > 1030 ? 'color: white;' : ''"
                                            @click="tz(item.id)">
                                            <div class="fontss">{{ item.title }}</div>
                                            <div style="width:99px;text-align: right;">{{ item.createTime }}</div>
                                        </div>
                                    </div>
                                </el-col>
                                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                                    <div v-for="item in arr2[1]" style="border-left: 1px solid white;padding:0 2rem;"
                                        :style="screens > 1030 ? '' : 'border:none;padding:0;'">
                                        <div class="qydtfont" :style="screens > 1030 ? 'color: white;' : ''"
                                            @click="tz(item.id)">
                                            <div class="fontss">{{ item.title }}</div>
                                            <div style="width:99px;text-align: right;">{{ item.createTime }}</div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </div>
                <div class="flexcenter">
                    <div :style="screens > 1030 ? '' : 'width: 90%;'"
                        style="width: 80%;padding: 1rem 1rem 0 1rem;margin-bottom: 1rem;">
                        <div class="bottomtitle" :style="screens > 1030 ? '' : 'font-size: 1.5rem;'">— —项目新闻— —</div>
                        <div class="flexcenter">
                            <div style="width: 100%;">
                                <el-row
                                    style="margin-top: 2rem; display: flex;justify-content: space-between;flex-direction: row; flex-wrap: wrap;flex-shrink:1;"
                                    :gutter="40">
                                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                                        <div class="bottomboxs" style="padding:0 2rem;">
                                            <div v-for="item in arr3[0]">
                                                <div class="qydtfont" @click="tz(item.id)">
                                                    <div class="fontss">{{ item.title }}</div>
                                                    <div style="width:99px;text-align: right;">{{ item.createTime }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </el-col>
                                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                                        <div class="bottomboxs" style="padding:0 2rem;">
                                            <div v-for="item in arr3[1]">
                                                <div class="qydtfont" @click="tz(item.id)">
                                                    <div class="fontss">{{ item.title }}</div>
                                                    <div style="width:99px;text-align: right;">{{ item.createTime }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getshouyeimg, shouyelist, getEssayById } from "../API/axios"
export default {
    props: ['screens'],
    data() {
        return {
            show: !false,
            arrdata1: [],
            arr2: [],
            arr3: [],
        }
    },
    methods: {
        infoWindowClose() {
            this.show = false
        },
        infoWindowOpen() {
            this.show = true
        },
        tz(id) {
            this.$router.push({ name: 'interior', query: { id: id } })
        },
        group(array = [], subGroupLength = 0) {
            let index = 0;
            const newArray = [];
            while (index < array.length) {
                newArray.push(array.slice(index, index += subGroupLength));
            }
            return newArray;
        }
    },
    created() {
        getEssayById("新闻动态").then((res) => {
            this.arrdata1 = res.data.data
        })
        getEssayById("行业资讯").then((res) => {
            this.arr2 = this.group(res.data.data, Math.ceil(res.data.data.length / 2))
        })
        getEssayById("项目新闻").then((res) => {
            this.arr3 = this.group(res.data.data, Math.ceil(res.data.data.length / 2))
        })
    },
}
</script>

<style scoped>
.bottom {
    width: 80%;
}

.bottomtitle {
    font-size: 2.5rem;
    font-weight: 700;
    color: rgba(0, 0, 0, 1);
    text-align: center;
    margin-top: 2rem;
}

.qydtfont {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 3rem;
    color: rgba(0, 0, 0, 1);
    display: flex;
    width: 100%;
}

.fontss {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.bottomboxs {
    border-radius: 28px;
    background: rgba(204, 204, 204, 1);
    border: 6px solid rgba(6, 69, 150, 1);
    margin-top: 1rem;
    height: 20rem;
    overflow-y: auto;
}

.bottomboxs::-webkit-scrollbar {
    display: none;
}

.bottomrow {
    height: 20rem;
    overflow: auto;
}

.bottomrow::-webkit-scrollbar {
    display: none;
}

.newsboxs {
    height: 20rem;
    overflow: auto;
}

.newsboxs::-webkit-scrollbar {
    display: none;
}
</style>