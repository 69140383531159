<template>
    <div style="height: 100%;">
        <div>
            <img src="../assets/jsyf.png" :style="screens > 1030 ? '' : 'height: 15rem;'"
                style="width: 100%;height: 30rem;">
        </div>
        <div :style="screens > 1030 ? '' : 'background: none;'"
            style="margin-top: 1rem; background: url(https://s11.ax1x.com/2024/01/13/pFPGXG9.png) no-repeat;padding: 2rem;background-size: 100%;">
            <div class="bottomtitle" :style="screens > 1030 ? 'color: white;' : ''">研发团队</div>
            <div class="flexcenter">
                <div :style="screens > 1030 ? '' : 'width: 100%;height: 15rem;'"
                    style="width: 40%;height: 30rem;margin-top: 2rem;">
                    <el-carousel :interval="4000" type="card" :height="screens > 1030 ? '30rem' : '15rem'">
                        <el-carousel-item>
                            <div class="carousel_box">
                                <div class="flexcenter">
                                    <img src="../assets/team1.png" alt="" style="width:380px;height: 350px;">
                                </div>
                                <div class="flexcenter">
                                    <div class="carousel_box_font">陈必承</div>
                                </div>
                                <div class="carousel_box_bottom carousel_box_font">
                                    专长领域：高分子化学；高分子物理；聚合物加工工程；物理化学；聚合物近代研究方法
                                </div>
                            </div>
                        </el-carousel-item>
                        <el-carousel-item>
                            <div class="carousel_box">
                                <div class="flexcenter">
                                    <img src="../assets/team2.png" alt="" style="width:380px;height: 350px;;">
                                </div>
                                <div class="flexcenter">
                                    <div class="carousel_box_font">苏科</div>
                                </div>
                                <div class="carousel_box_bottom carousel_box_font">
                                    专长领域：机械设计制造及其自动化专业，材料与化工专业
                                </div>
                            </div>
                        </el-carousel-item>
                        <el-carousel-item>
                            <div class="carousel_box">
                                <div class="flexcenter">
                                    <img src="../assets/team3.png" alt="" style="width:380px;height: 350px;">
                                </div>
                                <div class="flexcenter">
                                    <div class="carousel_box_font">拖晓航</div>
                                </div>
                                <div class="carousel_box_bottom carousel_box_font">
                                    专长领域：聚合物基复合材料，3D 打印，图像处理，品质管理
                                </div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>
        <div class="flexcenter">
            <div class="bottom">
                <div class="flexcenter">
                    <div style="width: 80%;padding: 1rem 1rem 0 1rem;margin-bottom: 1rem;">
                        <div class="bottomtitle" :style="screens > 1030 ? '' : 'font-size: 1.4rem;'">— —研发成果— —</div>
                        <div class="flexcenter">
                            <div class="bottomboxss">
                                <div class="bottomboxssfont">
                                    &nbsp &nbsp &nbsp &nbsp 公司致力于建筑材料制造研发运输领域，以让大企国企放心为己任，以工程制造为基础，运用科学技术为客户提供第一时间的支持与帮助。
                                </div>
                                <div class="bottomboxssfont">
                                    &nbsp &nbsp &nbsp &nbsp
                                    公司秉承“以质量求生存，以信誉促发展。坚持团结稳定，齐心协力干事业”的企业精神。用自身实力和科技创新发展，让员工进步令公司发展，使中国社会更加繁荣昌盛。
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['screens'],
    data() {
        return {
            show: !false,
        }
    },
    methods: {
        infoWindowClose() {
            this.show = false
        },
        infoWindowOpen() {
            this.show = true
        },
        tz() {
            this.$router.push('/interior')
        },
    },
}
</script>

<style scoped>
.carousel_box_font {
    font-size: 20px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    vertical-align: top;
}

.carousel_box_bottom {
    height: 20%;
    border-radius: 0px 0px, 22px, 22px;
    background: rgba(204, 204, 204, 1);
}

.carousel_box {
    padding: 1rem 0;
    border-radius: 22px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 7px 9px 4px 6px rgba(0, 0, 0, 0.25);
    height: 100%;
}

.box1 {
    border-radius: 33px;
    background: rgba(204, 204, 204, 1);
    border: 6px solid rgba(6, 69, 150, 1);
    width: 80%;
    margin-top: 1rem;
    font-size: 2rem;
    font-weight: 400;
    line-height: 3rem;
    color: rgba(0, 0, 0, 1);
    text-align: center;
    padding: 3rem;
}

.bottom {
    width: 80%;
}

.bottomtitle {
    font-size: 2.5rem;
    font-weight: 700;
    color: rgba(0, 0, 0, 1);
    text-align: center;
    margin-top: 2rem;
}

.bottomboxs {
    border-radius: 1rem;
    background: rgba(255, 255, 255, 1);
    box-shadow: 7px 9px 4px 6px rgba(0, 0, 0, 0.25);
    padding: 2rem;
    margin-top: 1rem;
}

.bottomboxss {
    border-radius: 2rem;
    background: rgba(204, 204, 204, 1);

    border: 6px solid rgba(6, 69, 150, 1);
    margin-top: 1rem;
    padding: 3rem;
}

.bottomboxsfont1 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
    color: rgba(0, 0, 0, 1);
    text-align: center;
}

.bottomboxsfont2 {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 3rem;
    color: rgba(0, 0, 0, 1);
    text-align: center;
}

.bottomboxsfont3 {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 2rem;
    color: rgba(0, 0, 0, 1);
}

.bottomboxssfont {
    font-size: 1rem;
    font-weight: 400;
    line-height: 2rem;
    color: rgba(0, 0, 0, 1);
}
</style>