import Vue from 'vue'
import App from './App.vue'
import BaiduMap from 'vue-baidu-map'
// 引入饿了么ui
import ElementUI from 'element-ui';
import VueRouter from 'vue-router'
import router from "./router/index"
import 'element-ui/lib/theme-chalk/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';
import * as echarts from 'echarts'

Vue.prototype.$echarts = echarts;

Vue.use(BaiduMap, {
  ak: 'tS3gj5W2tuRclt3HZwzPTb0OaydocZtG'  //这个地方是官方提供的ak密钥
})

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueRouter)
Vue.use(Vant);
new Vue({
  render: h => h(App),
  router:router
}).$mount('#app')
