<template>
    <div style="height: 100%;">
        <div>
            <img src="../assets/zbgg.png" :style="screens > 1030 ? '' : 'height: 15rem;'"
                style="width: 100%;height: 30rem;">
        </div>
        <div class="flexcenter" style="margin-top: 2rem;">
            <div style="width: 70%;">
                <div style="display: flex;justify-content: space-between;">
                    <div class="gywm">中标公告</div>
                    <div class="right">
                        <div v-for="item in arrdata">
                            <div class="rightlist" @click="xiangqing(item.id)">
                                <div class="rightlisttitle">{{ item.title }}</div>
                                <div class="rightlistdata">{{ item.createTime }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getshouyeimg, shouyelist, getEssayById } from "../API/axios"
export default {
    props: ['screens'],
    data() {
        return {
            show: !false,
            arrdata: [],
        }
    },
    methods: {
        infoWindowClose() {
            this.show = false
        },
        infoWindowOpen() {
            this.show = true
        },
        xiangqing(id) {
            this.$router.push({ name: 'adetail', query: { id: id } })
        }
    },
    created() {
        getEssayById("中标公告").then((res) => {
            this.arrdata = res.data.data
            console.log(this.arrdata)
        })
    },
}
</script>

<style scoped>
.right {
    border-radius: 1rem;
    background: rgba(204, 204, 204, 1);
    width: 78%;
    padding: 1rem;
}

.gywm {
    mix-blend-mode: multiply;
    border-radius: 1rem;
    background: rgba(71, 127, 191, 1);
    font-size: 2rem;
    font-weight: 500;
    line-height: 3rem;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    padding: 1rem;
    height: 10%;
    width: 20%;
}

.rightlist {
    border-radius: 0.8rem;
    background: rgba(224, 224, 224, 1);
    width: 100%;
    margin-bottom: 1rem;
    padding: 1rem;
}

.rightlisttitle {
    font-size: 2rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
}

.rightlistdata {
    font-size: 1rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.63);
    text-align: right;
    margin-top: 1rem;
}
</style>