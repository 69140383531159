import VueRouter from "vue-router";
import Home from "../components/Home.vue"
import Shouye from "../components/Shouye.vue"
import Jiandang from "../components/Jiandang.vue";
import GuanYuWoMen from "../components/GuanYuWoMen.vue"
import Detail from "@/components/detail.vue"
import Announcement from "@/components/announcement.vue"
import Adetail from "@/components/adetail.vue"
import Dynamic from "@/components/dynamic.vue"
import Interior from "@/components/interior.vue"
import Industry from "@/components/industry.vue"
import Culture from "@/components/culture.vue"
import Technology from "@/components/technology.vue"
import Contact from "@/components/contact.vue"
import IndustryDetail from "@/components/industryDetail.vue"
import Customer from "@/components/customer.vue"
import WuLiu from '@/components/WuLiu.vue'
import WuLiuDetail from '@/components/WuLiuDetail.vue'
const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: Home,
            redirect: "/index",
            query: { screens: screen },
            children: [
                {
                    path: '/index',
                    component: Shouye,
                    props: true,
                },
                {
                    path: '/jiandang',
                    component: Jiandang,
                    props: true,
                },
                {
                    path: '/guanyuwomen',
                    component: GuanYuWoMen,
                    props: true,
                },
                {
                    path: '/dynamic',
                    component: Dynamic,
                    props: true,
                },
                {
                    path: '/industry',
                    component: Industry,
                    props: true,
                },
                {
                    path: '/culture',
                    component: Culture,
                    name: 'culture',
                    props: true,
                },
                {
                    path: '/detail',
                    component: Detail,
                    name: 'detail',
                    props: true,
                },
                {
                    path: '/announcement',
                    component: Announcement,
                    name: 'announcement',
                    props: true,
                },
                {
                    path: '/adetail',
                    component: Adetail,
                    name: 'adetail',
                    props: true,
                },
                {
                    path: '/interior',
                    component: Interior,
                    name: 'interior',
                    props: true,
                },
                {
                    path: '/technology',
                    component: Technology,
                    name: 'technology',
                    props: true,
                },
                {
                    path: '/contact',
                    component: Contact,
                    name: 'contact',
                    props: true,
                },
                {
                    path: '/industryDetail',
                    component: IndustryDetail,
                    name: 'industryDetail',
                    props: true,
                },
                {
                    path: '/customer',
                    component: Customer,
                    name: 'customer',
                    props: true,
                },
                {
                    path:'/wuliu',
                    component:WuLiu,
                    name: 'WuLiu',
                    props: true,
                },
                {
                    path:'/wuliuDetail',
                    component:WuLiuDetail,
                    name:"WuLiuDetail",
                    props:true
                }
            ]
        }
    ]
})
export default router