<template>
    <div style="height: 100%;">
        <div class="flexcenter" style="margin-top: 2rem;">
            <div style="width: 70%;">
                <div style="display: flex;justify-content: space-between;">
                    <div class="left">
                        <div class="gywm">产品介绍</div>
                        <div v-for="item in gywm" style="margin-top: 5%;">
                            <div :class="['gywmfont',item.id===pcid ? 'show' : '']" @click="updateType(item.id)">{{ item.name }}</div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="rightfont">
                            <el-row style="flex-direction: row; flex-wrap: wrap;" :gutter="40" class="flexcenter" v-for="(item,index) in arr" :key="item.id"> 
                                <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                                    <img src="@/assets/qy.png" style="width: 100%;height: 100%;">
                                </el-col>
                                <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
                                    <div>功能特点：{{item.feature}}</div>
                                    <div>技术规格：{{item.specs}}</div>
                                    <div>应用案例：{{item.apply}}</div>
                                </el-col>
                            </el-row>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getTeamList,getProductClassify,getIndustryDetailListByPcid } from "../API/axios"
export default {
    props: ['screens'],
    data() {
        return {
            show: !false,
            gywm: [
                
            ],
            change: this.$route.query.name,
            arr: [],
            pcid:0
        }
    },
    methods: {
        infoWindowClose() {
            this.show = false
        },
        infoWindowOpen() {
            this.show = true
        },
        xiangqing() {
            this.$router.push('/xiangqing')
        },
        group(array = [], subGroupLength = 0) {
            let index = 0;
            const newArray = [];
            while (index < array.length) {
                newArray.push(array.slice(index, index += subGroupLength));
            }
            return newArray;
        },
        updateType(id){
            this.pcid = id
            getIndustryDetailListByPcid(id).then((res)=>{
            this.arr = res.data.data
        })
        }
    },
    watch: {
        $route(to, from) {
            this.change = to.query.name
        }
    },
    created() {
        getTeamList().then((res) => {
            this.arr = this.group(res.data.data, 5)
        })
        getProductClassify().then((res)=>{
            this.gywm = res.data.data
            if(this.gywm.length!==0){
                getIndustryDetailListByPcid(this.gywm[0].id).then((res)=>{
                this.pcid = this.gywm[0].id
                this.arr = res.data.data
            })
            }    
        })
        
    },
}
</script>
<style scoped>
.left {
    border-radius: 1rem;
    background: rgba(224, 224, 224, 1);
    width: 20%;
    height: 50%;
}

.right {
    border-radius: 1rem;
    background: rgba(204, 204, 204, 1);
    width: 78%;
    padding: 1rem;
}

.gywm {
    mix-blend-mode: multiply;
    border-radius: 1rem;
    background: rgba(71, 127, 191, 1);
    font-size: 2rem;
    font-weight: 500;
    line-height: 3rem;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    padding: 1rem;
    transform: translate(-4rem, 0)
}

.gywmfont {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 60px;
    color: rgba(0, 0, 0, 1);
    text-align: center;
    height: 60px;
}

.rightfont {
    font-size: 1rem;
    font-weight: 700;
    line-height: 3rem;
    color: rgba(0, 0, 0, 1);
}

.people {
    border-radius: 18px;
    background: rgba(163, 163, 163, 1);
    height: 15rem;
    margin-top: 1rem;
}
.show{
    border-radius: 12px;
    background-color: #CCCCCC;
}
</style>