<template>
    <div>
        <div v-if="screen >= 1030">
            <div style="height:4rem;">
                <div
                    style="height: 4rem;width: 100%;background: linear-gradient(90deg, rgba(55, 86, 222, 1) 0%, rgba(89, 19, 173, 1) 100%);position: fixed;z-index: 999;">
                    <el-row :gutter="10" style="width: 100%;margin-left: 7rem;padding-right: 300px;">
                        <el-col :span="3"> <span> <router-link to="/index" class="font">首页</router-link> </span></el-col>
                        <el-col :span="3"> <span><router-link to="/industry" class="font">产品介绍</router-link></span></el-col>
                        <!-- <el-col :span="3"> <span><router-link to="/announcement"
                                    class="font">中标公告</router-link></span></el-col> -->
                        <el-col :span="3"> <span><router-link to="/technology"
                                    class="font">技术研发</router-link></span></el-col>
                        <!-- <el-col :span="3"><span><router-link to="/partner" class="font">合作伙伴</router-link></span></el-col> -->
                        <!-- <el-col :span="3"><span><router-link to="/dynamic" class="font">企业动态</router-link></span></el-col> -->
                        <el-col :span="3"> <span><router-link to="/contact" class="font">联系方式</router-link></span></el-col>
                        <!-- <el-col :span="3"> <span><router-link to="/culture" class="font">企业文化</router-link></span></el-col> -->
                        <el-col :span="3">
                            <el-dropdown>
                                <span class="el-dropdown-link">
                                    <span><router-link to="/guanyuwomen" class="font">关于我们</router-link></span>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item><router-link
                                            to="/detail?name=公司历史">公司历史</router-link></el-dropdown-item>
                                    <el-dropdown-item><router-link
                                            to="/detail?name=领导团队">领导团队</router-link></el-dropdown-item>
                                    <el-dropdown-item><router-link
                                            to="/detail?name=企业资质">企业资质</router-link></el-dropdown-item>
                                    <el-dropdown-item><router-link
                                            to="/detail?name=企业文化">企业文化</router-link></el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </el-col>
                        <el-col :span="3"> <span><router-link to="/jiandang" class="font">党建</router-link></span></el-col>
                        <!-- <el-col :span="3"> <span><router-link to="/wuliu" class="font">物流查询</router-link></span></el-col> -->
                    </el-row>
                </div>
            </div>
        </div>
        <div>
            <router-view :screens="screen"></router-view>
        </div>
    </div>
</template>

<script>

export default {
    props: ['screen'],
}
</script>

<style scoped>
.el-menu {
    padding-left: 20% !important;
    margin-bottom: 40px !important;
    height: 4rem;
}

.el-menu-item {
    font-size: 1rem;
    user-select: none;
}

.font {
    font-size: 24px;
    font-weight: 400;
    font-family: 'SourceHanSansCN-Regular';
    line-height: 4rem;
    color: white;
}
</style>