<style scoped>
.title{
    width: 100vw;
    height: 35vh;
}
.title img{
    width: 100%;
    height: 100%;
}
.left{
    background-color: #061A8D;
    color: white;
    width: 280px;
    height: 180px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    margin-right: 40px;
}
.right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #CCCCCC;
    width: 500px;
    height: 300px;
    border-radius: 25px;
}
.content{
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
</style>
<template>
<div>
    <div class="title"><img src="@/assets/wuliu.png" ></div>
    <div class="content">
        <div class="left">
            查询方式：<br>
            （选其一）
        </div>
        <div class="right">
           <div>手机尾号：<el-input v-model="telephone" style="width: 340px;" placeholder="手机后四位" /></div>  
        <br>
           <div>单号查询：<el-input v-model="num" style="width: 340px" placeholder="请输入单号" /></div>
        <br>   
           <div><button style="width:100px;height: 40px;border: 4px #061A8D solid;border-radius: 5px;" @click="$router.push('/wuliuDetail')" >查询</button></div>
        </div>
    </div>
</div>

</template>
<script>
export default{
    data(){
        return {
            telephone:"",
            num:''
        }
    }
}
</script>