<template>
    <div class="jd">
        <div style="width: 90%;">
            <div class="header">
                <el-row
                    style="display: flex;justify-content: space-between;flex-direction: row; flex-wrap: wrap;flex-shrink:1;"
                    :gutter="40">
                    <el-col :xs="24" :sm="24" :md="15" :lg="15" :xl="15">
                        <div :style="screens >= 990 ? 'height: 350px;' : 'height: 200px;'">
                            <el-carousel :interval="5000" arrow="always" style="margin-top: 2rem;">
                                <el-carousel-item v-for="item, index in imgarr" :key="item.id">
                                    <img :src="item.building">
                                </el-carousel-item>
                            </el-carousel>
                        </div>

                    </el-col>
                    <el-col :xs="24" :sm="24" :md="9" :lg="9" :xl="9">
                        <div class="box">
                            <div class="ch flexcenter" style="line-height: 50px;"
                                :style="screens >= 1030 ? 'font-size:1.5rem;' : 'font-size:1rem;'">信息预览</div>
                            <ul class="main-ul" style="margin-top: 0.5rem;margin-left: 2rem;height:18rem;padding: 1rem;">
                                <li v-for="item in arrdata1" :key="item.id" class="nenlieli"
                                    @click="funxinweixiangqingye(item.id)"
                                    :style="screens >= 1030 ? 'font-size:1.1rem;' : 'font-size:1rem;'"
                                    style=" line-height: 2rem;">{{ "■" + item.title }}</li>
                            </ul>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div class="flexcenter">
                <div style="width: 60%;">
                    <div class="fenye">
                        <div class="fenyetitle" :style="screens > 1030 ? '' : 'font-size: 1.4rem;'">— —<span
                                style="color:rgba(0, 0, 0, 1);">盛世繁歌</span>— —</div>
                        <el-row style="margin-top: 2rem;flex-direction: row; flex-wrap: wrap;" :gutter="40"
                            class="flexcenter">
                            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                                <div id="main-left">
                                    <ul class="main-ul">
                                        <li @click="funxinweixiangqingye(item.id)" class="nenlieli"
                                            v-for="item in arrdata2[0]" :key="item.id"
                                            :style="screens >= 1030 ? 'font-size:1.5rem;' : 'font-size:1rem;'">
                                            {{ "■" + item.title }}</li>
                                    </ul>
                                </div>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                                <div id="main-left">
                                    <ul class="main-ul">
                                        <li @click="funxinweixiangqingye(item.id)" class="nenlieli"
                                            v-for="item in arrdata2[1]" :key="item.id"
                                            :style="screens >= 1030 ? 'font-size:1.5rem;' : 'font-size:1rem;'">
                                            {{ "■" + item.title }}</li>
                                    </ul>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
            <div class="flexcenter" style="margin-top: 2rem;">
                <div style="width: 100%;">
                    <div
                        style="background:url(https://zl-925896390-1317109654.cos.ap-nanjing.myqcloud.com/2023/08/14/862d158d5a304324a704cc59eb963f27.jpg) no-repeat;border-radius: 34px;padding:1rem 1rem 2rem 1rem;background-size: 100%;">
                        <div class="bottomtitle" style="color: white;" :style="screens > 1030 ? '' : 'font-size: 1.4rem;'">—
                            —清廉从业— —</div>
                        <el-row class="bottomrow"
                            style="margin-top: 2rem; display: flex;justify-content: space-between;flex-direction: row; flex-wrap: wrap;flex-shrink:1;">
                            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                                <div v-for="item in arrdata3[0]" style="border-right: 1px solid white;padding:0 4rem;">
                                    <div class="qydtfont" :style="screens > 1030 ? 'color: white;' : 'text-align: left;'"
                                        @click="funxinweixiangqingye(item.id)">{{ "■" + item.title }}
                                    </div>
                                </div>
                            </el-col>
                            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                                <div v-for="item in arrdata3[1]" style="border-left: 1px solid white;padding:0 4rem;">
                                    <div class="qydtfont" :style="screens > 1030 ? 'color: white;' : ''"
                                        @click="funxinweixiangqingye(item.id)">{{ "■" + item.title }}
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
            <div class="flexcenter">
                <div style="width: 100%;padding: 1rem 1rem 0 1rem;margin-bottom: 1rem;">
                    <div class="fenyetitle">— —<span style="color:rgba(0, 0, 0, 1);">企业党建</span>— —</div>
                    <div class="flexcenter">
                        <div class="bottomboxss">
                            <el-row class="bottomrow"
                                style="display: flex;justify-content: space-between;flex-direction: row; flex-wrap: wrap;flex-shrink:1;">
                                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                                    <div v-for="item in arrdata4[0]" style="padding:0 3rem;"
                                        :style="screens > 1030 ? '' : 'padding:0;'">
                                        <div class="qydtfont" @click="funxinweixiangqingye(item.id)">{{ "■" + item.title }}
                                        </div>
                                    </div>
                                </el-col>
                                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                                    <div v-for="item in arrdata4[1]" style="padding:0 3rem;"
                                        :style="screens > 1030 ? '' : 'padding:0;'">
                                        <div class="qydtfont" @click="funxinweixiangqingye(item.id)">{{ "■" + item.title }}
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getjiandangtupian, getEssayById } from "../API/axios"
export default {
    props: ['screens'],
    data() {
        return {
            imgarr: [],
            arrdata1: [],
            arrdata2: [],
            arrdata3: [],
            arrdata4: [],
        }
    },
    methods: {
        funxinweixiangqingye(id) {
            this.$router.push({ name: 'interior', query: { id: id } })
        },
        group(array = [], subGroupLength = 0) {
            let index = 0;
            const newArray = [];
            while (index < array.length) {
                newArray.push(array.slice(index, index += subGroupLength));
            }
            return newArray;
        }
    },
    created() {
        getjiandangtupian().then((res) => {
            this.imgarr = res.data.data
        })
        getEssayById("信息预览").then((res) => {
            this.arrdata1 = res.data.data
        })
        getEssayById("盛世繁歌").then((res) => {
            this.arrdata2 = this.group(res.data.data, Math.ceil(res.data.data.length / 2))
        })
        getEssayById("清廉从业").then((res) => {
            this.arrdata3 = this.group(res.data.data, Math.ceil(res.data.data.length / 2))
        })
        getEssayById("企业党建").then((res) => {
            this.arrdata4 = this.group(res.data.data, Math.ceil(res.data.data.length / 2))
        })
    }
}
</script>

<style scoped>
.header {
    width: 100%;
    border-radius: 24px;
    background: rgba(194, 194, 194, 1);
    margin-top: 2rem;
    padding: 0 1rem 2rem 1rem;
}

.box {
    border-radius: 28px;
    background: rgba(255, 255, 255, 0.71);
    margin-top: 2rem;
}

.fenyetitle {
    font-size: 2rem;
    font-weight: 700;
    line-height: 3rem;
    color: rgba(212, 48, 48, 1);
    text-align: center;
    margin-top: 2rem;
}

.bottomtitle {
    font-size: 2.5rem;
    font-weight: 700;
    color: rgba(0, 0, 0, 1);
    text-align: center;
    margin-top: 2rem;
}

.qydtfont {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 2rem;
    color: rgba(0, 0, 0, 1);
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.el-carousel {
    width: 100% !important;
    height: 100%;
}

.el-carousel /deep/ .el-carousel__container {
    height: 100% !important;
}

.jd {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.middle-left {
    width: 200px;
    height: 300px;
    float: left;
    margin-top: 50px;
    margin-left: 70px;
}

.middle-left ul li {
    display: block;
    width: 100px;
    height: 70px;
    list-style: none;
    font-size: 20px;
    line-height: 70px;
    text-align: center;
}

.main-left {
    border-bottom: 1px solid rgb(196, 0, 0);
    width: 6.25rem;
}

#main-left {
    border-radius: 2rem;
    background: rgba(224, 222, 222, 1);

    border: 6px solid rgba(212, 48, 48, 1);
    padding: 2rem;
    margin-top: 1rem;
}

.main-left h3 {
    width: 100px;
    line-height: 50px;
    border-bottom: 5px solid rgb(196, 0, 0);
}

#dang {
    color: rgb(196, 0, 0);
    font-size: 28px;
}

.main-ul {
    height: 13rem;
    overflow: auto;
}

.main-ul::-webkit-scrollbar {
    display: none;
}

.main-ul li {
    display: block;
    width: 100%;
    line-height: 50px;
    list-style: none;
    font-size: 18px;
}

.nenlieli {
    cursor: pointer;
    user-select: none;
    line-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.nenlieli:hover {
    color: brown;
}

img {
    width: 100%;
    height: 100%;
}

.main-ul li span {
    display: block;
    width: 10px;
    height: 10px;
    background-color: rgb(196, 0, 0);
    float: left;
    margin-top: 20px;
    margin-right: 5px;
}

.bottomboxss {
    border-radius: 2rem;
    background: rgba(224, 222, 222, 1);
    border: 6px solid rgba(212, 48, 48, 1);
    margin-top: 1rem;
    padding: 3rem;
    width: 80%;
}

.bottomrow {
    height: 20rem;
    overflow: auto;
}

.bottomrow::-webkit-scrollbar {
    display: none;
}
</style>