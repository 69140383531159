import createAxios from "./config";
export const getJiaoYiZhanShi = () => {          //暂时无用
    return createAxios({
        url: '/transaction',
        method: 'GET',
    })
}
export const setFromDataGongYingShang = (file) => {  //提交供应商申请数据
    return createAxios({
        url: '/saveSuppliers',
        method: 'POST',
        data: file,
        headers: { 'content-type': 'multipart/form-data' },

    })
}

export const uploadimg = (file) => {      //暂时无用
    return createAxios({
        url: '/upload',
        method: 'POST',
        data: {
            file
        },
        headers: { 'content-type': 'multipart/form-data' },

    })
}

export const getshouyeimg = () => {   //首页轮播图
    return createAxios({
        url: '/getHome',
        method: 'GET',
    })
}
export const getjiandangtupian = () => {        //党建轮播图
    return createAxios({
        url: '/getBuilding',
        method: 'GET',
    })
}

export const getGongYingShangLieBiao = (name = "", type = "") => {  //得到供应商列表
    return createAxios({
        url: '/suppliersByCity',
        method: 'GET',
        params: {
            name,
            type
        }
    })
}

export const getGongYingShangLieBiaos = (type = "") => {  //得到供应商列表
    return createAxios({
        url: '/suppliersByCity',
        method: 'GET',
        params: {
            type
        }
    })
}
export const subway = (pageNo = "", pageSize = "", area = "") => {
    return createAxios({
        url: '/getSubwayListPage',
        method: 'GET',
        params: {
            pageNo,
            pageSize,
            area
        }
    })
}

export const subways = (pageNo = "", pageSize = "") => {
    return createAxios({
        url: '/getSubwayListPage',
        method: 'GET',
        params: {
            pageNo,
            pageSize
        }
    })
}

export const shouyelist = () => {
    return createAxios({
        url: '/OutStandSuppliers',
        method: 'GET',
    })
}

export const xiangqing = (id = "") => {
    return createAxios({
        url: '/getSuppliersById',
        method: 'GET',
        params: {
            id
        }
    })
}

export const map = () => {
    return createAxios({
        url: '/getSubwayMap',
        method: 'GET',
    })
}
export const maps = () => {
    return createAxios({
        url: '/getSubwayAddress',
        method: 'GET',
    })
}

export const getUnion = (id = "") => {
    return createAxios({
        url: '/getUnion',
        method: 'POST',
        params: {
            id
        }
    })
}

export const getEssayById = (type = "") => {
    return createAxios({
        url: '/getEssayById1',
        method: 'POST',
        params: {
            type
        }
    })
}


export const getAllById = (id = "") => {
    return createAxios({
        url: '/getAllById',
        method: 'POST',
        params: {
            id
        }
    })
}

export const getVisited = () => {
    return createAxios({
        url: '/getVisited',
        method: 'GET',
    })
}

export const visited = () => {
    return createAxios({
        url: '/visited',
        method: 'POST',
    })
}

export const getList = () => {
    return createAxios({
        url: '/getList',
        method: 'GET',
    })
}

export const getTeamList = () => {   //首页轮播图
    return createAxios({
        url: '/getTeamList',
        method: 'GET',
    })
}

export const getProductClassify = ()=>{
    return createAxios({
        url:"/getProductClassify",
        method:'GET'
    })
}

export const getIndustryDetailListByPcid = (pcid)=>{
    return createAxios({
        url:`/getIndustryDetailList?pcid=${pcid}`,
        method:'GET'
    })
}